<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay

      :show="loading"
      rounded="sm"
      opacity="0.95"
      class="w-100"
    >
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link
          class="brand-logo d-flex align-items-center"
        >
          <b-img
            src="../../../assets/images/logo/starworks.jpg"
            style="height: 60px;"
          />
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          class="d-none d-lg-flex align-items-center p-5"
          lg="8"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              :src="imgUrl"
              alt="Login V2"
              fluid
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="4"
        >
          <b-col
            class="px-xl-2 mx-auto"
            lg="12"
            md="6"
            sm="8"
          >
            <div
              v-if="currentdomain==='impunjab'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/imp.png"
                style="width:60%"
              />
            </div>
            <div
              v-else-if="currentdomain==='ginserv'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/ginserv.png"
                style="width:60%"
              />
            </div>
            <div
              v-else-if="currentdomain==='aghub'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/aghub.png"
                style="width:60%"
              />
            </div>
            <div
              v-else-if="currentdomain==='actionplaniitb'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/abhyuday.png"
                style="width:60%"
              />
            </div>
            <div
              v-else-if="currentdomain==='aic-prestige'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/aic-prestige.png"
                style="width:60%"
              />
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/starworks.jpg"
                style="width:60%"
              />
            </div>
            <b-button-group class="my-2 flex w-100 shadow">
              <b-button
                class="bg-primary text-white"
                variant="outline-primary"
              >
                Log in
              </b-button>
              <b-button
                variant="outline-primary"
                :to="{name:'auth-register', query: $route.query}"
              >
                Register
              </b-button>
            </b-button-group>
            <b-card-title
              class="mb-1 font-weight-bold text-center"
              title-tag="h2"
            >
              Welcome to {{ incubatorname }}! 👋
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              Please sign-in to your account and start the adventure
            </b-card-text>
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                    vid="email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{name:'auth-forgot-password', query: $route.query}">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                    vid="password"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <!--              <b-form-group>-->
                <!--                <b-form-checkbox-->
                <!--                  id="remember-me"-->
                <!--                  v-model="status"-->
                <!--                  name="checkbox-1"-->
                <!--                >-->
                <!--                  Remember Me-->
                <!--                </b-form-checkbox>-->
                <!--              </b-form-group>-->

                <!-- submit buttons -->
                <b-button
                  :disabled="invalid || loading"
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="loading"
                    small
                  />
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{name:'auth-register', query: $route.query}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text>
            <b-card-text
              class="text-center"
              style="margin-top:-10px"
            >
              <span>Forgot Password? </span>
              <b-link :to="{name:'auth-forgot-password', query: $route.query}">
                <span>&nbsp;Reset it Here</span>
              </b-link>
            </b-card-text>

          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
      <template #overlay>
        <div class="text-center">
          <p class="display-4">
            "Give me a firm spot on which to stand, and I shall move the earth"
            <br>
            <span class="h3">— Archimedes of Syracuse</span>
          </p>

          <div class="d-inline-flex">
            <b-spinner
              variant="primary"
              class="my-1"
            />
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BButtonGroup,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BRow,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BButtonGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentdomain: '',
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      incubator: [],
      incubatorname: 'Starworks Prime',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      randomFact: '',
      // validation rules
      required,
      email,
      userInfo: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  beforeMount() {
    this.getHost()
  },
  methods: {
    getHost() {
      this.currentdomain = window.location.host.split('.')[0].toString()
    },
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            this.loading = true
            useJwt.login(this.userEmail, this.password)
              .then(response => {
                const userData = {}
                userData.fullName = response.attributes.name
                userData.userEmail = response.attributes.email
                if (jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Superadmin')) {
                  userData.role = 'admin'
                  userData.ability = [{
                    action: 'manage',
                    subject: 'Incubator',
                  },
                  {
                    action: 'read',
                    subject: 'Auth',
                  },
                  {
                    action: 'read',
                    subject: 'Dashboard',
                  }]
                } else if ((jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Mentor'))) {
                  userData.role = 'mentor'
                  userData.ability = [
                    {
                      action: 'read',
                      subject: 'Auth',
                    },
                    {
                      action: 'read',
                      subject: 'Dashboard',
                    },
                    {
                      action: 'manage',
                      subject: 'Mentor',
                    }]
                } else {
                  userData.role = 'user'
                  userData.ability = [
                    {
                      action: 'read',
                      subject: 'Auth',
                    },
                    {
                      action: 'read',
                      subject: 'Dashboard',
                    },
                    {
                      action: 'manage',
                      subject: 'User',
                    }]
                }
                localStorage.setItem('userData', JSON.stringify(userData))
                localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
                this.$ability.update(userData.ability)
                this.$apollo.query({
                  query: gql`
                      {
                        users_customuser(where: {email: {_eq: "${response.attributes.email}"}}) {
                          id
                          first_name
                          email
                          users_associationtables{
                            id
                            organization_id
                            program_id
                            role
                            designation
                            users_organizationtable {
                              title
                            }
                          }
                        }
                      }`,
                })
                  .then(({ data }) => {
                    userData.id = data.users_customuser[0].id
                    userData.associatedOrgDetails = data.users_customuser[0].users_associationtables
                    // ? This is just for demo purpose as well.
                    // ? Because we are showing eCommerce app's cart items count in navbar
                    // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
                    localStorage.setItem('userData', JSON.stringify(userData))
                    localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
                    this.loading = false
                    this.$router.replace(this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/')
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Welcome ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                          },
                        })
                      })
                    // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  })
              })
              .catch(error => {
                this.loading = false
                this.$refs.loginForm.setErrors({
                  email: error.message,
                  password: ' ',
                })
              })
          }
        })
    },
  },
  apollo: {
    incubator: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
          {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}}) {
              id
              logo
              title
            }
          }`
      },
      update: data => {
        const urlPrefix = window.location.host.split('.')[0]
        this.incubatorname = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? 'Starworks Prime' : urlPrefix
        return data.users_organizationtable
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
